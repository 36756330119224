<template>
  <div class="shipping-address">
    <div class="title">收货地址</div>
    <div class="content">
      <div class="new-address">
        <p>已保存的收货地址：</p>
        <a @click="add">新增收货地址</a>
      </div>
      <table width="100% ">
        <tr>
          <th width="234px">收货单位</th>
          <th width="240px">收货地址</th>
          <th width="120px">收货联系人</th>
          <th width="144px">联系人手机号码</th>
          <th>操作</th>
        </tr>
        <tr v-for="(list, index) in address" :key="index">
          <td>{{ list.consignee | consignee }}</td>
          <td
            :title="
              list.province + list.city + list.county + list.detailAddress
            "
          >
            <span class="address"
              >{{ list.province }}{{ list.city }}{{ list.county
              }}{{ list.detailAddress }}</span
            >
          </td>
          <td>{{ list.nameAddress }}</td>
          <td>{{ list.phoneAddress }}</td>
          <td>
            <p v-if="list.defaultAddress">默认收货地址</p>
            <a v-else style="margin-left: 0" @click="SetDefault(list.id)"
              >设为默认收货地址</a
            >
            <a @click="copyreader(list)">编辑</a>
            <a @click="deleteAddress(list.id)">删除</a>
          </td>
        </tr>
      </table>
      <el-empty
        :image="img"
        description="暂时没有收货地址哦~~"
        v-if="address.length === 0"
        :image-size="230"
      ></el-empty>
    </div>
    <transition name="el-fade-in-linear">
      <div class="form" v-show="newAddress">
        <div class="box">
          <div class="title">
            <span>{{ FormState === 0 ? "新增" : "编辑" }}收货地址</span>
            <svg-icon icon-class="ic-close" @click="Tip" />
          </div>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="122px"
            class="demo-ruleForm"
            label-position="left"
            size="small"
            status-icon
          >
            <el-form-item label="收货单位">
              <el-input v-model="ruleForm.ReceivingUnit"></el-input>
            </el-form-item>
            <el-form-item label="收货地址" prop="ShippingAddress">
              <el-cascader
                size="large"
                :options="options"
                v-model="ruleForm.ShippingAddress"
                @change="handleChange"
              >
              </el-cascader>
              <!-- <el-input v-model="ruleForm.ShippingAddress"></el-input> -->
            </el-form-item>
            <el-form-item label="详细地址" prop="DetailedAddress">
              <el-input v-model="ruleForm.DetailedAddress"></el-input>
            </el-form-item>
            <el-form-item label="收货联系人" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="联系人手机号码" prop="num">
              <el-input v-model.number="ruleForm.num"></el-input>
            </el-form-item>
            <el-form-item label="是否默认地址" prop="default" status-icon>
              <el-radio-group v-model="ruleForm.default">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <button @click="submitForm('ruleForm')">保存收货地址</button>
        </div>
      </div>
    </transition>
    <!-- <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" center>
      <span>所填的信息将不被保存，确定关闭窗口？</span>
      <span slot="footer" class="dialog-footer">
        <button @click="closeForm">确定关闭</button>
        <button @click="dialogVisible = false">取消关闭</button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import { regionData, CodeToText } from "element-china-area-data";

export default {
  name: "shipping-address",

  data() {
    return {
      img: require("@/assets/images/EmptyAddress.png"),
      FormState: 0,
      options: regionData, //城市信息
      selectedOptions: "", //选中的城市信息
      store: "", //商品信息
      address: [], //用户地址
      goods: "",
      Default: "", //默认第一条信息
      message: "", // 留言框信息
      // dialogVisible: false, //新增地址关闭弹窗
      OnInput: false, //留言框是否展开
      More: false, //更多地址
      newAddress: false, //新增地址显示隐藏
      ruleForm: {
        ReceivingUnit: "", //收货单位
        ShippingAddress: "", //所在地区
        DetailedAddress: "", //详情地址
        name: "", //名字
        num: "", //手机号
        default: "", //是否默认地址
        addressCode: "", //地区号
      },
      rules: {
        ShippingAddress: [
          { required: true, message: "请选择所在地区", trigger: "blur" },
        ],
        DetailedAddress: [
          { required: true, message: "请填写详细地址", trigger: "blur" },
        ],
        name: [
          { required: true, message: "请填写收货联系人", trigger: "blur" },
          { max: 6, message: "最多为六个字符", trigger: "blur" },
        ],
        num: [
          { required: true, message: "请填写联系人手机号码", trigger: "blur" },
          {
            required: true,
            validator: (rule, value, callback) => {
              if (/^(?:(?:\+|00)86)?1\d{10}$/.test(value) == false) {
                callback(new Error("手机号格式不正确"));
              } else {
                callback();
              }
            },
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        default: [
          { required: true, message: "请选择是否默认地址", trigger: "blur" },
        ],
      },
    };
  },
  filters: {
    consignee(val) {
      if (val) {
        return val;
      } else {
        return "无";
      }
    },
  },
  created() {
    this.getAddress();
  },

  methods: {
    async getAddress() {
      let address = JSON.parse(sessionStorage.getItem("userAddress"));
      if (address) {
        this.address = address;
      } else {
        const data = await this.$API.personalUser.queryAddress({
          object: {
            pageNum: 1,
            pageSize: 1,
          },
        });
        this.address = data.data.data;
        sessionStorage.setItem("userAddress", JSON.stringify(this.address));
      }
    },
    //新增收货地址按钮
    add() {
      this.FormState = 0;
      for (let k in this.ruleForm) {
        this.ruleForm[k] = "";
      }
      this.newAddress = true;
    },
    //新增收货接口
    async insertAddress() {
      let city = [];
      for (let i = 0; i < this.ruleForm.ShippingAddress.length; i++) {
        city.push(CodeToText[this.ruleForm.ShippingAddress[i]]);
      }
      const data = await this.$API.personalUser
        .insertAddress({
          object: {
            addressCode: this.ruleForm.addressCode,
            province: city[0],
            city: city[1],
            county: city[2],
            consignee: this.ruleForm.ReceivingUnit,
            country: "",
            defaultAddress: this.ruleForm.default,
            detailAddress: this.ruleForm.DetailedAddress,
            id: "",
            nameAddress: this.ruleForm.name,
            phoneAddress: this.ruleForm.num,
          },
        })
        .then((data) => {
          this.$message({
            message: "添加成功",
            type: "success",
            offset: "450",
            duration: 1000,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$message.error("添加失败");
        });
      sessionStorage.clear("userAddress");
      this.getAddress();
    },
    //修改地址接口
    async updateAddress() {
      const data = await this.$API.personalUser
        .updateAddress({
          object: {
            addressCode: this.ruleForm.addressCode,
            province: this.selectedOptions[0],
            city: this.selectedOptions[1],
            county: this.selectedOptions[2] ? this.selectedOptions[2] : "",
            consignee: this.ruleForm.ReceivingUnit,
            country: "",
            defaultAddress: this.ruleForm.default,
            detailAddress: this.ruleForm.DetailedAddress,
            id: this.ruleForm.id,
            nameAddress: this.ruleForm.name,
            phoneAddress: this.ruleForm.num,
          },
        })
        .then((data) => {
          this.$message({
            message: "更改成功",
            type: "success",
            offset: "450",
            duration: 1000,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$message.error("更改失败");
        });
      sessionStorage.clear("userAddress");
      this.getAddress();
    },
    Tip() {
      // for (let k in this.ruleForm) {
      //   if (this.ruleForm[k] != "") {
      //     this.t = true;
      //   }
      // }
      // if (this.t) {
      //   this.dialogVisible = true;
      // } else {
      //   this.$refs.ruleForm.clearValidate();
      //   this.newAddress = false;
      //   this.t = false;
      // }
      this.newAddress = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.FormState === 0) {
            this.insertAddress();
            this.closeForm();
          } else {
            this.updateAddress();
            this.closeForm();
          }
          // this.closeForm();
          // this.newAddress = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleChange() {
      var loc = [];
      let addressCode = "";
      for (let i = 0; i < this.ruleForm.ShippingAddress.length; i++) {
        loc.push(CodeToText[this.ruleForm.ShippingAddress[i]]);
        addressCode += this.ruleForm.ShippingAddress[i] + ",";
      }
      this.ruleForm.addressCode = addressCode.slice(0, addressCode.length - 1);
      this.selectedOptions = loc;
    },
    closeForm() {
      for (let k in this.ruleForm) {
        this.ruleForm[k] = "";
      }
      this.newAddress = false;
      this.$refs.ruleForm.clearValidate();
      this.dialogVisible = false;
      this.t = false;
    },
    //设置默认地址
    async SetDefault(id) {
      this.$confirm("您确定要设置当前地址为默认地址吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const data = await this.$API.personalUser
            .updateAddress({
              object: {
                defaultAddress: true,
                id: id,
              },
            })
            .then((data) => {
              this.$message({
                message: "设置成功",
                type: "success",
                offset: "450",
                duration: 1000,
              });
            })
            .catch((err) => {
              console.log(err);
              this.$message.error("设置失败");
            });

          sessionStorage.clear("userAddress");
          this.getAddress();
        })
        .catch(() => {
          this.$message({
            message: "取消成功",
            type: "success",
            offset: "450",
            duration: 1000,
          });
        });
    },
    //删除收货地址
    async deleteAddress(id) {
      this.$confirm("您确定要删除选中地址吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const data = await this.$API.personalUser
            .deleteAddress({
              object: {
                id: id,
              },
            })
            .then((data) => {
              this.$message({
                message: "删除成功",
                type: "success",
                offset: "450",
                duration: 1000,
              });
            })
            .catch((err) => {
              console.log(err);
              this.$message.error("删除失败");
            });
          sessionStorage.clear("userAddress");
          this.getAddress();
        })
        .catch(() => {
          this.$message({
            message: "取消成功",
            type: "success",
            offset: "450",
            duration: 1000,
          });
        });
    },
    //编辑
    copyreader(Default) {
      this.FormState = 1;
      let Code = Default.addressCode.split(",");
      console.log(Code);

      this.ruleForm = {
        addressCode: Default.addressCode,
        ReceivingUnit: Default.consignee, //收货单位
        ShippingAddress: Code, //所在地区
        DetailedAddress: Default.detailAddress, //详情地址
        name: Default.nameAddress, //名字
        num: Default.phoneAddress, //手机号
        default: Default.defaultAddress, //是否默认地址
        id: Default.id,
        province: Default.province,
        city: Default.city,
        county: Default.county,
      };
      this.newAddress = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.shipping-address {
  border: 1px solid #dddddd;
  // height: 100%;
  min-height: 492px;
  color: #333333;
  .title {
    height: 38px;
    background: #f3f3f3;
    border-bottom: 1px solid #dddddd;
    font-size: 17px;
    font-weight: bold;
    line-height: 37px;

    padding-left: 24px;
  }
  .content {
    padding: 20px 24px;
    .new-address {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 20px;
      margin-bottom: 12px;
      p {
        font-size: 14px;
      }
      a {
        font-size: 14px;
        font-weight: bold;
        color: #086ac2;
        cursor: pointer;
      }
      a:hover {
        color: var(--subjectColor);
      }
    }
    table {
      font-size: 12px;

      border: 1px solid #ddecfb;
      tr:nth-child(1) {
        height: 40px;
        background: #f3f8fe;
      }
      tr {
        th:nth-child(1),
        td:nth-child(1) {
          padding-left: 30px;
        }
        th:last-child {
          padding-right: 30px;
          text-align: center;
        }
        th {
          text-align: start;
          font-weight: bold;
          padding-right: 60px;
        }
        td {
          padding: 14px 0;
          padding-right: 60px;
        }
        .address {
          white-space: normal;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        td:last-child {
          display: flex;
          height: 60px;
          width: 100%;
          align-items: center;
          padding-right: 30px;

          justify-content: flex-end;
          a {
            margin-left: 18px;
            color: #086ac2;
            cursor: pointer;
          }
          a:hover {
            color: var(--subjectColor);
          }
        }
      }
    }
    .el-empty {
      padding: 70px 0;
    }
  }
  .settlement {
    border: 1px solid #e8e8e8;
    background: #f5f5f5;
    width: 1200px;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    padding-left: 20px;
    margin-bottom: 60px;
    p {
      cursor: pointer;
    }
    .rental {
      margin-right: 24px;
      span {
        font-size: 20px;
        color: #fe3132;
      }
    }
    button {
      width: 140px;
      height: 56px;
      background: var(--subjectColor);
      border: none;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .form {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.39);
    z-index: 2000;
    .box {
      width: 694px;
      height: 480px;
      background: rgba(255, 255, 255, 1);
      opacity: 1;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -347px;
      margin-top: -240px;
      border: 5px solid rgba(0, 0, 0, 0.55);
      border-radius: 4px;
      .title {
        width: 100%;
        background: #f5f5f5;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px;
        .svg-icon {
          cursor: pointer;
          font-size: 18px;
        }
      }

      ::v-deep .el-form {
        padding: 24px;
        padding-bottom: 0;
        .el-form-item {
          margin-bottom: 16px;
          .el-form-item__label:before {
            margin: 0;
          }
          .el-form-item__content {
            .el-input {
              input {
                width: 300px;
                border-radius: 0;
                padding: 0 12px;
              }
              .el-input__suffix {
                left: 304px;
                right: auto;
                font-size: 16px;
                color: #22bb55;
              }
            }
            .el-form-item__error {
              left: 330px;
              top: 23%;
            }
            .el-input__inner:focus {
              border-color: var(--subjectColor) !important;
            }
            .el-input__inner:target {
              border-color: var(--subjectColor);
            }
            .el-cascader .el-input.is-focus .el-input__inner {
              border-color: var(--subjectColor);
            }
            .el-radio__input.is-checked .el-radio__inner {
              border-color: var(--subjectColor);
              background: var(--subjectColor);
            }
            .el-radio__inner:hover {
              border-color: var(--subjectColor);
            }
            .el-radio__input.is-checked + .el-radio__label {
              color: var(--subjectColor);
            }
          }
        }
        .el-form-item:last-child {
          .el-form-item__error {
            left: 122px;
            top: 23%;
          }
        }
        .el-form-item:nth-child(2) {
          .el-cascader {
            line-height: 32px;
          }
          .el-input__inner {
            height: 34px;
          }
          .el-input__suffix-inner {
            position: absolute;
            left: -36px;
            top: -3px;
            color: #dcdfe6;
          }
          .el-icon-circle-close {
            line-height: 34px;
          }
        }
      }
      button {
        width: 132px;
        height: 36px;
        background: var(--subjectColor);
        border-radius: 2px;
        border: none;
        font-size: 14px;
        line-height: 34px;
        color: #ffffff;
        cursor: pointer;
        margin-left: 144px;
      }
    }
  }
}
</style>